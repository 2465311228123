import React from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion";

import Ceo from '../images/ceo.svg';
import Chang from '../images/chan.svg'; 
import Meee from '../images/tech.svg';
import Nee from '../images/solu.svg';


import TeamCard from '../components/TeamCard';
import TeamMarquee from '../components/TeamMarquee';

const Section = styled.section`
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`;


const GridLayout = styled.div`
    max-width: 1247px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    grid-column-gap: 14px;
    grid-row-gap: 40px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
        width: 100% ;
        place-items: center;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 42px;
        place-items: center;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        place-items: center;
        grid-row-gap: 20px;
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
        place-items: center;
        grid-row-gap: 5px;
    }
`;

const Line = styled.div`
    width: 50%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
    margin-left: 110px;
`;



const TeamContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    justify-content: center;
`;

const MainHead = styled.h1`
    width: 64.5067873303rem;
    font-size: 83px;
    line-height: .8;
    position: relative;
    color: white;
    font-family: NeueUltra;
`;

const ParaContainer = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
`;

const Para = styled.p`
    font-size: 18px;
    color: white;
    opacity: 0.8;
    margin-bottom: 1rem;
    font-family: NeueLight;
    line-height: 24px;
    text-align: center;

    @media screen and (max-width: 408px) {
        padding: 0 10px 0 10px;
    }
`;

const NewButton = styled(motion.a)`
    padding: 0.875rem 1.5rem;
    margin-top: 20px;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(145,134,231) 12.82%, rgb(146,201,249) 41.96%, rgb(199,248,255) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    width: fit-content !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: fit-content !important;
    font-family: Neue;

    &:hover {
      background-position: left bottom;
    }
`;


const TeamSection = () => {
    return (
        <>
        <TeamMarquee />
    
        
        <Section>
            {/* <TopContainer>
                <Line />
                <TopHeading>Our Team</TopHeading>
            </TopContainer> */}
            <GridLayout>
                <TeamCard team={Ceo} name="CEO"/>
                <TeamCard team={Chang} name="Operations Lead"/>
                <TeamCard team={Meee} name="Technical Lead"/>
                <TeamCard team={Nee} name="Solutions Lead"/>  
            </GridLayout>
            
        </Section>
        <TeamContainer>
            <ParaContainer>
                <Para>
                If you wish to actively contribute to our mission, reach out to us
                </Para>
                <NewButton
                    target="_blank"
                    href='mailto:wearofvalue@gmail.com'
                    whileHover={{
                        scale: 1.1
                    }}>
                    Email Us
                </NewButton>
            </ParaContainer>
        </TeamContainer>
        </>
    )
}

export default TeamSection;