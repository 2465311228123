import React from 'react';
import styled from 'styled-components';

import Background from '../images/bg.mp4'

import Logo from '../images/log.svg';
import Arrow from '../images/arrow.svg';

const Footer = styled.section`
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    width: 100%;
    padding: 30px 70px 30px 70px;
    background: #141414;
    gap: 20px; 
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1440px) {
      flex-direction: column;
      align-items: center;
      justify-content: center; 
      padding: 30px 70px 30px 70px;
    }

    @media screen and (max-width: 500px) {
      padding: 20px 30px 20px 30px;
    }
`;

const Row = styled.div`
    flex-basis: 0;
    flex-direction: row;
    flex-grow: 1;
    gap: 40px;
    display: flex;
    min-width: 50%;


    @media screen and (max-width: 660px) {
      flex-direction: column;
      gap: 0px;
      align-items: center;
      justify-content: center;
    } 
`;


const Row1 = styled.div`
    flex-basis: 0;
    flex-direction: row;
    flex-grow: 1;
    gap: 20px;
    display: flex;
    min-width: 50%;

    @media screen and (max-width: 690px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
`;

const InsideContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;

    @media screen and (max-width: 690px) {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
`;

const InsideContainer1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    margin-right: 10px;

    @media screen and (max-width: 690px) {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
`;

const Heading = styled.h2`
  font-size: 1.4rem;
  color: white;
  font-family: Cont;
  margin-top: 30px;

  @media screen and (max-width: 800px) {
      margin-top: 60px;
    }

    @media screen and (max-width: 676px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 20px;
    }
`;


const Paragraph2 = styled.a`
    margin: 50px 0;
    color: #fff;
    font-family: Neue;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      border-bottom: 2px solid white;
    }

    @media screen and (max-width: 1440px) {
      margin: 30px 0;
    }

    @media screen and (max-width: 800px) {
      margin: 30px 0;
    }

    @media screen and (max-width: 672px) {
      margin: -12px 0;
    }

    @media screen and (max-width: 690px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 500px) {
      margin: 10px 0;
    }
`;

const Paragraph = styled.p`
    margin: 50px 0;
    color: #fff;
    font-family: Neue;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    text-align: center;

    @media screen and (max-width: 1440px) {
      margin: 30px 0;
    }

    @media screen and (max-width: 800px) {
      margin: 10px 0;
    }

    @media screen and (max-width: 450px) {
      margin: 10px 0;
    }

`;

const Paragraph1 = styled.p`
    margin: 10px 0;
    color: #fff;
    opacity: 0.7;
    font-family: Neue;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    width: 80%;

    @media screen and (max-width: 690px) {
      width: 100%;
      margin: 0px;
    }
    
`;


const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    gap: 20px;
`;




const Unorderlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px ;

    @media screen and (max-width: 660px) {
      margin-top: 20px ;
    }
`;

const Orderlist = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SocialLinks = styled.a`
      font-family: Cont;
      font-size: 1em;
      text-decoration: none;
      text-transform: uppercase;
      color: white;
      align-items: center;
      justify-content: center;
      display: inline-block;
      text-align: center;
      font-weight: 600;
      cursor: pointer;

      ::after {
        content: "";
        display: block;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        height: 2px;
        width: 0px;
        background-image: linear-gradient(35deg,#F7F1E3 0%,#F3E4D7 16.5%,#F4BBC8 33%,#CD84F1 49.5%,#9980FA 66%,#B0FCFF 82.5%,#4AD9FB 100%);
    }

      &:hover::after{
        width: 100%;
        transition: all 0.4s;
      }

      @media screen and (max-width: 1548px) {
        font-size: 0.8em;
      }

      @media screen and (max-width: 860px) {
        font-size: 0.7em;
      }

      @media screen and (max-width: 640px) {
        font-size: 0.6em;
      }

      @media screen and (max-width: 420px ) {

      }
`;

const ArrowIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-left: 10px;
  margin-bottom: 10px;

  /* @media screen and (max-width: 450px) {
    display: none;
  } */
`;

const CopyConatiner = styled.div`
    display: flex;
    margin-top: 2px;
`;


const LogoImage = styled.img`
    width: 70px;
    height: 70px;
    overflow: 'hidden';

    @media screen and (max-width: 690px) {
      width: 70px;
      height: 70px;
      padding-bottom: 30px;
    }

    @media screen and (max-width: 600px) {
      width: 80px;
      height: 80px;
    }

    
`;

const NewFooter = () => {
  return (
    <Footer>
        <Row1>
          <InsideContainer>
             
                  <LogoImage src={Logo}/>
              
              <Paragraph1>
                  Wear of Value does not currently have any tokens on any
                  blockchain. Only trust information coming from our official social platforms and website.
              </Paragraph1>
          </InsideContainer>
          <InsideContainer1>
              <Heading>
                  SAY HELLO
              </Heading>
              <Paragraph2
                href='mailto:weare@wearofvalue.com'
                target='_blank'
              >
                  weare@wearofvalue.com
              </Paragraph2>
          </InsideContainer1>
        </Row1>
        <Column>
        <Row>
        <Unorderlist>
                <Orderlist>
                    <SocialLinks
                      href='https://opensea.io/WearOfValue?tab=created'
                      target="_blank"
                    >
                    Opensea
                    </SocialLinks>
                    <ArrowIcon src={Arrow}/>
                </Orderlist>
            </Unorderlist>
            <Unorderlist>
                <Orderlist>
                    <SocialLinks
                        href='https://www.linkedin.com/company/wearofvalue/'
                        target="_blank"
                    >LinkedIn</SocialLinks>
                    <ArrowIcon src={Arrow}/>
                </Orderlist>
            </Unorderlist>
            <Unorderlist>
                <Orderlist>
                    <SocialLinks
                      href='https://www.instagram.com/wearofvalue/'
                      target="_blank"
                    >
                    Instagram
                    </SocialLinks>
                    <ArrowIcon src={Arrow}/>
                </Orderlist>
            </Unorderlist>
            <Unorderlist>
                <Orderlist>
                    <SocialLinks
                      target="_blank"
                      href='https://twitter.com/WearOfValue?t=fV7GbjZiMtjyyyRzpOPLWQ&s=09'
                    >
                    Twitter
                    </SocialLinks>
                    <ArrowIcon src={Arrow}/>
                </Orderlist>
            </Unorderlist>
            
            {/* <Unorderlist>
                <Orderlist>
                    <SocialLinks>Discord</SocialLinks>
                    <ArrowIcon src={Arrow}/>
                </Orderlist>
            </Unorderlist> */}
            
            
        </Row>
        <CopyConatiner>
              <Paragraph>
                  © copyright 2022 - Wear Of Value
              </Paragraph>
        </CopyConatiner>
        </Column>
    </Footer>

  )
}

export default NewFooter