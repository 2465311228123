import React from 'react';
import styled from 'styled-components';

import Back from '../images/miss.svg';
import BackOne from '../images/mismob.svg';

const Section = styled.section`
    background-image: url(${Back});
    padding: 94px 78px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    background-position: right;

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 0 20px 50px 20px;
        background-image: url(${BackOne});
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: start;
    grid-gap: 1.3888888889vw;
    gap: 1.3888888889vw;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        text-align: start;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
   

    @media screen and (max-width: 500px ) {
        display: none;
    }
`;

const Paragraph = styled.p`
    width: 29.0416666667vw;
    margin-bottom: 1.7361111111vw;
    color: white;
    font-size: 25px;
    font-family: NeueLight;
    line-height: 26px;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        font-size: 23px;
    }

    @media screen and (max-width: 530px) {
        font-size: 22px;
        padding: 20px;
    }

    @media screen and (max-width: 450px) {
        font-size: 18px;
        padding: 0px;
        width: 100%;
    }

    @media screen and (max-width: 430px) {
        font-size: 18px;
        padding: 0px;
    }
`;



const Heading = styled.h2`
    width: 42.7083333333vw;
    color: white;  
    font-family: Cont;
    font-size: 52px;
    text-transform: uppercase;
    text-align: justify;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        font-size: 42px;
    }

    @media screen and (max-width: 530px) {
        font-size: 35px;
        padding: 20px;
    }

    @media screen and (max-width: 450px) {
        font-size: 28px;
        padding: 0px;
        width: 100%;
    }

    @media screen and (max-width: 430px) {
        font-size: 24px;
        padding: 0px;
    }
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
   

    @media screen and (max-width: 1000px) {
        padding: 0px
    }
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const Mission = () => {
    return (
        <Section>
            <TopContainer>
                <Line />
                    <TopHeading>Mission</TopHeading>
            </TopContainer>
            <Container> 
                <RowContainer>
                    <Paragraph>
                        When looking at our current Web3.0 environment, the space mainly consists of infrastructure, financial tools, gaming, and NFTs.
                    </Paragraph>
                    <Paragraph>
                    There is little involvement of the World of Fashion, other than big Corperations of the traditional Fashion Industry.
                    </Paragraph>
                    <Paragraph>
                    We believe we can leverage the power of Web3.0 to disrupt the space that has been dominated by the same few big players. We believe in a future whereby the way we interact with Fashion is not simply as consumers.
                    </Paragraph>
                    
                </RowContainer>
                <Heading>To disrupt the fashion industry and revolutionize it to the digital space by tokenized NFTs, Defi and Fashionization</Heading>
            </Container>
        </Section>
    )
};

export default Mission;
