const TokenData = [
    {
      "percentage": "15%",
      "forWhat": "Team",
      "lockText": "0% TGE 5% unlocked each month after",
      "noOf": '150,000,000',
    },
    {
        "percentage": "5%",
        "forWhat": "Community",
        "lockText": "20% TGE 10% unlocked each month after",
        "noOf": '50,000,000',
    },
    {
        "percentage": "10%",
        "forWhat": "Reseves",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '100,000,000',
    },
    {
        "percentage": "10%",
        "forWhat": "Development",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '100,000,000',
    },
    {
        "percentage": "10%",
        "forWhat": "Marketing",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '100,000,000',
    },
    {
        "percentage": "20%",
        "forWhat": "Public Sale",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '200,000,000',
    },
    {
        "percentage": "20%",
        "forWhat": "Seed Liquidity",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '200,000,000',
    },
    {
        "percentage": "3%",
        "forWhat": "Advisory",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '30,000,000',
    }
    ,
    {
        "percentage": "2%",
        "forWhat": "Affiliates",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '20,000,000',
    }
    ,
    {
        "percentage": "5%",
        "forWhat": "Partnerships",
        "lockText": "0% TGE 5% unlocked each month after",
        "noOf": '50,000,000',
    }
  ]

  export default TokenData;