import React from 'react';
import styled from 'styled-components';

import Batch from '../images/black.svg'
import BackOne from '../images/blackmob.svg';

const Wrapper = styled.div`
    width: 390px;
    height: 550px;
    background-image: url(${Batch});
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &:hover{
        display: inline-block;
        box-sizing: border-box;
        border: 4px solid transparent;
        background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(199,248,255) 12.82%, rgb(146,201,249) 41.96%, rgb(145,134,231) 75.06%, rgb(238,164,188) 107.66%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
        opacity: 0.9;
        background-color: transparent !important;
        color: white !important;
    }

    @media screen and (max-width: 422px) {
        width: 320px;
        height: 500px;
        background-image: url(${BackOne});
    }
`;


const HeadConatiner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 25px;
    padding-top: 20px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardName = styled.h1`
    font-size: 34px;
    color: white;
    font-family: Cont;
    margin-left: 13px;

    @media screen and (max-width: 422px) {
        font-size: 24px;
    }
`;


const ImgWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ImgContainer = styled.div`
    width: 300px;
    height: 380px;
    padding-right: 5px;
    padding-left: 5px;

    @media screen and (max-width: 422px) {
        width: 260px;
        height: 340px;
        padding-bottom: 22px;
    }
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;


const Subtitle = styled.p`
    margin-top: 10px;
    color: white;
    font-size: 16px;
    line-height: 27.84px;
    font-weight: 400;
    margin-left: 13px;
    font-family: Neue;


    @media screen and (max-width: 422px) {
        font-size: 14px;
    }
`;



const TokenVideoCard = ({name, video, about}) => {
    return (
        <Wrapper>
            <HeadConatiner>
                <TextWrapper>
                    <CardName>{name}</CardName>
                    <Subtitle>{about}</Subtitle>
                </TextWrapper>
            </HeadConatiner>
            <ImgWrapper>
                <ImgContainer>
                <Video autoPlay='autoplay' muted loop>
                    <source src={video} type='video/mp4' />
                </Video>
                </ImgContainer>
            </ImgWrapper>
        </Wrapper>
    )
};

export default TokenVideoCard;