import React from 'react';
import styled from "styled-components";
import { motion } from 'framer-motion/dist/framer-motion'

const Marquee = styled.div`
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 100px;
    border: 3px solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: transparent;
    color: white !important;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Track =  styled(motion.div)`
    position: absolute;
    white-space: nowrap;
`;

const Text = styled.h1`
    margin: 20px 0;
    font-size: 5rem;
    font-family: Cont;
    /* -webkit-text-fill-color: transparent; 
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white; */
    color: white;

    @media screen and (max-width: 420px) {
      font-size: 3rem;
    }
`;

const marqueeVariants = {
    animate: {
      x: [0, -2035],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 20,
          ease: "linear",
        },
      },
    },
  };

const MarqueeComponent = () => {
    return (
        <>
          <Marquee>
            <Track variants={marqueeVariants}
            animate="animate">
                <Text>
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                Discover. Advertise. Rent. Earn. Discover. Advertise. Rent. Earn.
                </Text>
            </Track>
          </Marquee>    
        </>
    )
}

export default MarqueeComponent
