import React from "react";

import styled from 'styled-components';
import CrossMarquee from '../components/CrossMarquee';


//Cross Logos

import Eth from '../images/eth.png';
import Solana from '../images/sol.png';
import Cosmos from '../images/atom.png';
import Avax from '../images/avax.png';
import Matic from '../images/matic.png';
import Near from '../images/near.png';
import Elgo from '../images/el.png';
import Algo from '../images/algo.png';
import Dot from '../images/dot.png';

const images = [
  Eth,
  Solana,
  Cosmos,
  Avax,
  Matic,
  Near,
  Elgo,
  Algo,
  Dot
]

export const GridLayout = styled.div`
    max-width: 1247px;
    margin-right: auto;
    margin-left: auto;
    align-items: stretch;
    grid-column-gap: 89px;
    grid-row-gap: 0px;
    display: grid;
    place-items: center;
    grid-auto-columns: 30fr;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 42px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
    }
`;

const Container = styled.section`
    overflow: hidden;
    width: 100%;
    margin-top: 6rem;
    user-select: none;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
   
`;


const Heading = styled.h1`
    font-family: Cont;
    font-size: 40px;
    transform: translate(0px, 0px);
    opacity: 1;
    color: white;


    @media screen and (max-width: 760px) {
        font-size: 25px;
        text-align: center;
        padding: 0 12px 0 12px;
    }

    @media screen and (max-width: 420px) {
        padding: 0 17px 0 17px;
    }
`;

export const IconBlock =  styled.div`
    width: 140px;
    height: 140px;
    display: flex;
    align-items: flex-start;
    padding-left: 6px;
`;

export const Img = styled.img`
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 0px;
    background-size: cover;
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;  

export default function CrossChain() {
  // const data = [Etheruem, Solana, Polygon];
  return (
    <Container>
      <Heading>
        Cross-Chain Compatible With
      </Heading>
      <ImageContainer>
        <CrossMarquee images={images} />
      </ImageContainer>
      
    </Container>
  );
}
