import React from 'react';
import styled from 'styled-components';
import VideoCard from './VideoCard';

import Gold from '../images/WovGold.mp4';
import Diamond from '../images/WovDiamond.mp4';
import Platinum from '../images/WovPlatinum.mp4';
import Onyx from '../images/WovOnyx.mp4';

export const Section = styled.section`
    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`;


export const TopContainer = styled.div`
    max-width: 1247px;
    min-width: 200px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5vh;
    border-radius: 9px;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media screen and (max-width: 490px) {
        padding-top: 2vh;
    }
`;

const Heading = styled.h1`
    font-family: Cont;
    font-size: 40px;
    transform: translate(0px, 0px);
    opacity: 1;
    color: white;

    @media screen and (max-width: 760px) {
        font-size: 25px;
        text-align: center;
    }
`;

const Paragraph = styled.p`
    font-family: Neue;
    font-size: 1.5rem;
    width: 100%;
    color: white;
    margin-top: 10px;
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }

    @media screen and (max-width: 920px) {
      font-size: 18px;
    }
`;

export const CollectionGrid = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding: 30px;
    grid-column-gap: 25px;
    grid-row-gap: 20px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 52px;
        grid-column-gap: 30px;  
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 52px;
        grid-column-gap: 40px;
        margin-right: auto;
        margin-left: auto;
        padding: 0px;
    }
`;


const VideoSection = () => {
    return (
        <Section>
            <TopContainer>
                <Heading>
                    Genesis NFT
                </Heading>
                <Paragraph>
                    Your key to a more Exciting, Immersive and Rewarding future.
                </Paragraph>
            </TopContainer>
            <CollectionGrid>
                <VideoCard video={Onyx} name="Onyx" likes="04" />
                <VideoCard video={Gold} name="Gold" likes="01" />
                <VideoCard video={Platinum} name="Platinum" likes="03" />
                <VideoCard video={Diamond} name="Diamond" likes="02" />
            </CollectionGrid>
        </Section>
    )
}

export default VideoSection