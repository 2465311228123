import React from 'react';
import styled from 'styled-components';

import TokenVideoCard from './TokenVideoCard';
import TokenCardWhite from './TokenCardWhite';

import Black from '../images/BlackToken.mp4';
import White from '../images/WhiteToken.mp4';

import Back from '../images/back77.svg';

const Section = styled.section`
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 94px 78px;

    @media screen and (max-width: 1200px) {
        padding: 4px 78px;
    }

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 10px;
    }
`;

const Container = styled.div`
    display: block;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;

    @media screen and (max-width: 991px) {
        max-width: 728px;
    }

    ::before {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }

    ::after {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
    margin-left: 10px;
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const LayoutGrid = styled.div`
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    margin-bottom: 0;
    grid-column-gap: 20px;
    grid-row-gap: 18px;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    place-items: center;

    @media screen and (max-width: 991px) {
        grid-column-gap: 24px;
    }

    @media screen and (max-width: 827px) {
        grid-template-columns: 1fr ;
    }

    @media screen and (max-width: 400px) {
        padding: 0px;
    }
`;


const WoVUtility = () => {
    return (
        <Section>
                <TopContainer>
                    <Line />
                        <TopHeading>Duo Token Ecosystem</TopHeading>
                </TopContainer>
            <Container>
                <LayoutGrid>
                    <TokenCardWhite 
                        video={White} 
                        name="WOV Token"
                        about="Allows for us to focus on building a fantastic product and services paid with our utility token"
                    />
                    <TokenVideoCard 
                        video={Black} 
                        name="VOW Token"
                        about="Allows for the community to shape the direction of our developing fashion products"
                    />
                </LayoutGrid>
            </Container>
        </Section>
    )
}

export default WoVUtility