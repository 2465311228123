import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import styled from 'styled-components';


const Box = styled.div`
    width: 240px;
    height: 300px;
    position: absolute;
    font-size: 25px;
    line-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  
    overflow: hidden;

    @media screen and (max-width: 420px) {
      height: 200px;
    }
`;

const Image = styled.img`
    max-width: 150px;
    max-height: 50px;
`;

const ImageConatiner = styled.div`
    width: 240px;
    /* height:150px; */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Boxes = styled.div`
    position: relative;
    left: -240px;
`;

const Wrapper = styled.div`
    width: 1540px;
    height: 300px;
    position: relative;
    margin: auto;
    overflow: hidden;

    @media screen and (max-width: 420px) {
      height: 200px;
    }
`;

const BlockWidth = 1440 / 6;

const ProccessBlocks = ({ images }) => {
  const [marqueeBlocks, setMarqueeBlocks] = useState([]);

  useEffect(() => {
    const _marqueeBlocks = [];
    let block = [];
    while (_marqueeBlocks.length < 9) {
      for (let i = 0; i < images.length; i++) {
        block.push(images[i]);
        if (block.length === 10) {
          _marqueeBlocks.push(block);
          block = [];
        }
      }
    }
    setMarqueeBlocks(_marqueeBlocks);
  }, [images]);

  if (marqueeBlocks.length === 0) {
    return null;
  }

  return <CrossMarquee marqueeBlocks={marqueeBlocks} />;
};

const CrossMarquee = ({ marqueeBlocks }) => {
  const marqueeElements = useRef([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const marqueeTween = useRef();

  const BannerWidth = BlockWidth * marqueeBlocks.length;

  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
      // marqueeTween.pause().kill();
    };
  }, []);

  useEffect(() => {
    marqueeInitialSet();
    marqueeTween.current && marqueeTween.current.pause().kill();
    marqueeTween.current = gsap.to(marqueeElements.current, {
      x: `-=${BannerWidth}`,
      ease: "none",
      repeat: -1,
      duration: 60,
      rotation: 0.1,
      modifiers: {
        x: (x) => {
          return (
            ((parseFloat(x) + BlockWidth * (marqueeBlocks.length - 1)) %
              BannerWidth) +
            "px"
          );
        }
      }
    });
  }, [screenWidth]);

  const marqueeInitialSet = () => {
    gsap.set(marqueeElements.current, {
      // xPercent: -100,
      x: function (index) {
        return BlockWidth * index;
      }
    });
  };

  const resizeHandler = () => {
    gsap.set(marqueeElements.current, { clearProps: "all" });
    setScreenWidth(window.innerWidth);
  };

  const marqueeElementsRefHandler = (e, i) => {
    marqueeElements.current[i] = e;
  };

  const renderMarqueeElements = () => {
    return marqueeBlocks.map((e, i) => (
      <Box
        className="box"
        key={`marquee-${i}`}
        ref={(el) => marqueeElementsRefHandler(el, i)}
      >
        <ImageConatiner>
          <Image className="img1" alt="" src={e[0]} />
        </ImageConatiner>
      </Box>
    ));
  };

  return (
    <Wrapper
      onMouseEnter={() => {
        marqueeTween.current.pause();
      }}
      onMouseLeave={() => {
        marqueeTween.current.resume();
      }}
    >
      <Boxes>{renderMarqueeElements()}</Boxes>
    </Wrapper>
  );
};

export default ProccessBlocks;
