import React from 'react';
import styled from 'styled-components';


import SolutionCard from './SolutionCard';


import Back from '../images/back77.svg';

const Section = styled.section`
    padding: 94px 78px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 20px;
    }

`;

const CollectionGrid = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding: 30px;
    grid-column-gap: 25px;
    grid-row-gap: 20px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 52px;
        grid-column-gap: 10px;  
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 52px;
        grid-column-gap: 40px;
        margin-right: auto;
        margin-left: auto;
    }

    @media screen and (max-width: 420px) {
        grid-template-columns: 1fr;
        padding: 0px;
    }

    
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
    margin-left: 10px;
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const TextConatiner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        padding-top: -0px;
    }
`;

const Headline = styled.h1`
    font-family: Cont;
    font-size: 46px;
    text-align: center;
    background-image: linear-gradient(35deg,#FBF4EC 0%,#ECD7C8 16.5%,#ffffff 33%,#ffffff 66%,#92C9F9 82.5%,#C7F8FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;
    width: 100%;
    margin-top: 80px;


    @keyframes animatedText{
        to{
            background-position: 100%; 
        }
    }

    @media screen and (max-width: 1460px) {
        font-size: 44px;
        text-align: center;
        padding-top: 50px;

    }

    @media screen and (max-width: 1360px) {
        font-size: 37px;
        text-align: center;
        padding-top: 50px;
  
    }

    @media screen and (max-width: 920px) {
        font-size: 35px;
        text-align: center;
        padding-top: 50px;
    }

    @media screen and (max-width: 540px) {
        font-size: 28px;
        text-align: center;
        padding-top: 0px;
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 20px;
    }
`;

const Paragraph = styled.p`
    font-family: Neue;
    font-size: 1.5rem;
    color: #f1f2f6;
    margin-top: 30px;
    text-align: center;
    opacity: 0.9;

    @media screen and (max-width: 410px) {
        margin-top: 20px ;
        text-align: center;
        font-size: 1rem;
    }
`;

const SolutionSection = () => {
    return (
        <Section>
            <TopContainer>
                <Line />
                <TopHeading>Solutions</TopHeading>
            </TopContainer>
            <TextConatiner>
                <Headline>The Future Of Fashion</Headline>
                
            </TextConatiner>
            <CollectionGrid>
                <SolutionCard 
                    name="Wearable"
                    description="The journey of fashion starts with revolutionizing your wearble. Own wearables that are meaningful and unique just for you."
                />
                <SolutionCard 
                    name="Own"
                    description="With the help of blockchain, every fashion item you own is no longer just a wearable. We extend its utility to function much like an asset."
                />
                <SolutionCard 
                    name="Value"
                    description="Get rewarded as you wear and showcase your identity. Connect with others like yourself who believe in our mission to revolutionize fashion."
                />
            </CollectionGrid>
            
        </Section>
    )
}

export default SolutionSection