import React from 'react';
import styled from 'styled-components';

import Back from '../images/JoinDiscord.svg';
import BackOne from '../images/mob.svg';


const Section = styled.section`
    padding: 4px 78px;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    display: block;
    background-position: right;
    padding-bottom: 120px;

    @media screen and (max-width: 991px) {
        padding: 7px 78px 40px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        background-image: url(${BackOne});
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 560px) {
        margin-bottom: 40px;
    }
`;


const Paragraph = styled.p`
    width: 59.0416666667vw;
    margin-bottom: 1.7361111111vw;
    color: white;
    font-size: 25px;
    font-family: NeueLight;
    text-align: center;
    margin-top: 30px;

    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }

    @media screen and (max-width: 900px) {
        font-size: 18px;
    }

    @media screen and (max-width: 550px) {
        display: none;
    }
`;

const NewButton = styled.a`
    padding: 0.875rem 1.5rem;
    position: relative;
    margin-top: 50px;
    text-decoration: none;
    display: inline-block;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(199,248,255) 12.82%, rgb(146,201,249) 41.96%, rgb(145,134,231) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    width: fit-content !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: fit-content !important;
    font-family:  NeueUltra;
    

    &:hover {
      background-position: left bottom;
    }
`;

const Heading = styled.h2`
    width: 52.7083333333vw;
    color: white;  
    font-family: NeueUltra;
    font-size: 92px;
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 75px;
    }

    @media screen and (max-width: 900px) {
      font-size: 60px;
      width: 80%;
    }

    @media screen and (max-width: 490px) {
      font-size: 35px;
      line-height: 40px;
    }

`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
    align-items: center;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const NewSocial = () => {
    return (
        <Section>
        <Line />
            <TopContainer>
                
                    <TopHeading></TopHeading>
            </TopContainer>
            <Container> 
                <Heading> Join Our Twitter For More Updates</Heading>
                <Paragraph>
                    Be the first to know of our announcements and participate in events held in our 
                    Twitter channel! Get a chance to talk to the team behind Wear Of Value and be
                    actively involved in shaping the future of Fashion 3.0
                </Paragraph>
                <NewButton
                    target="_blank"
                    href='https://twitter.com/WearOfValue?t=fV7GbjZiMtjyyyRzpOPLWQ&s=09'
                >Join Twitter
                </NewButton>
            </Container>
        </Section>
    )
};

export default NewSocial;
