import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Back from '../images/problem.svg';
import Backmob from '../images/probmob.svg';
import BackTwo from '../images/problemip.svg';

import Relation from '../images/heart.svg';
import Engagement from '../images/engag.svg';
import Touch from '../images/per.svg';

const Section = styled.section`
    padding: 94px 78px;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media screen and (max-width: 1200px) {
        padding: 4px 78px;
    }

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
        background-image: url(${BackTwo});
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 20px;
        padding-left: 20px;
    }


    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 3px 20px 50px 20px;
        background-image: url(${Backmob});
    }
`;

export const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
`;

export const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TextConatiner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        padding-top: -0px;
    }
`;

const Headline = styled.h1`
    font-family: Cont;
    font-size: 46px;
    text-align: center;
    background-image: linear-gradient(35deg,#FBF4EC 0%,#ECD7C8 16.5%,#ffffff 33%,#ffffff 66%,#92C9F9 82.5%,#C7F8FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;
    width: 100%;
    margin-top: 80px;


    @keyframes animatedText{
        to{
            background-position: 100%; 
        }
    }

    @media screen and (max-width: 1460px) {
        font-size: 44px;
        text-align: center;
        padding-top: 50px;
    }

    @media screen and (max-width: 1360px) {
        font-size: 37px;
        text-align: center;
        padding-top: 50px;
  
    }

    @media screen and (max-width: 920px) {
        font-size: 35px;
        text-align: center;
        padding-top: 50px;
    }

    @media screen and (max-width: 540px) {
        font-size: 28px;
        text-align: center;
        padding-top: 0px;
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 20px;
    }
`;

const Paragraph = styled.p`
    font-family: Neue;
    font-size: 1.5rem;
    color: #f1f2f6;
    margin-top: 30px;
    text-align: center;
    opacity: 0.9;
    width: 50%;

    @media screen and (max-width: 870px) {
    
        width: 80%;
    }

    @media screen and (max-width: 410px) {
        margin-top: 20px ;
        text-align: center;
        font-size: 18px;
        width: 80%;
    }
`;

const GridLayout = styled.div`
    max-width: 1247px;
    margin-right: auto;
    align-items: stretch;
    grid-column-gap: 49px;
    grid-row-gap: 40px;
    display: grid;
    place-items: flex-start;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 42px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
    }
`;

const Card = styled(motion.div)`
    padding-right: 20px;
    padding-left: 20px;
    min-width: 26vw;
    background-color: #141414;
    border-radius: 30px;
  

    @media screen and (max-width: 767px) {
        max-width: 600px;
    }
`;

const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 10px 20px;
    background-color: #141414;
    /* border-bottom: 4px solid #f4ae6e; */
    border-radius: 8px;
    box-shadow: 11px 0 28px 0px rgb(0 0 0 / 15%);
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */

`;

const Heading = styled.h1`
    font-family: Cont;
    color: white;
    font-size: 20px;
    line-height: 21px;
    font-weight: 500;
    margin-left: 13px;
    margin-top: 30px;

    @media screen and (max-width: 1300px) {
        font-size: 16px;
    }
`;



const IconBlock =  styled.div`
    width: 120px; height: 120px ;
    display: flex;
    align-items: flex-start;
    padding-left: 6px;
`;

const Img = styled.img`
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 0px;
    background-size: cover;
`;

const Problems = () => {
  return (
        <Section>
            <TopContainer>
                <Line />
                <TopHeading>Problems</TopHeading>
            </TopContainer>
            <TextConatiner>
                <Headline>
                    Fashion Space Centralization
                </Headline>
                <Paragraph>
                There is a heavy top-down control in the current Fashion Industry. The need to have an identity is drowned out by the same few Corporations selling us their version of Fashion.
                </Paragraph>
            </TextConatiner>
            <GridLayout>
                <Card whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                        <IconContainer>
                                <IconBlock>
                                    <Img src={Relation} alt='relation'/>
                                </IconBlock>
                                <div style={{ width: '100%'}}>
                                <Heading>NFTs that embody uniqueness lack a relationship with the user</Heading>
                                </div>
                            </IconContainer>
                </Card>
                    <Card whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>                       
                        <IconContainer>
                            <IconBlock>
                                <Img src={Engagement} alt='engagement'/>
                            </IconBlock>
                            <div>
                                <Heading>NFTs that provide a digital identity lack engagement with the users</Heading>
                                </div>
                        </IconContainer> 
                        
                    </Card>
                    <Card whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                        <IconContainer>
                            <IconBlock>
                                <Img src={Touch} alt='personaltouch'/>
                            </IconBlock>
                            <div>
                                <Heading>NFTs that serve the function of powering game mechanics lack personal touch</Heading>
                                </div>
                        </IconContainer>    
                    </Card>
            </GridLayout>
        </Section>
  )
}

export default Problems