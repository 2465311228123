import React from 'react';
import styled from 'styled-components';

import Star from '../images/star.svg';

const Section = styled.div`
    margin-top: -70px;
    background-color: black;
    padding: 60px 10px 20px 10px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1280px) {
        padding: 0 7.5vw;
    }

    @media screen and (min-width: 768px) {
        padding: 0 6rem;
    }

    @media screen and (min-width: 568px) {
        padding: 0 2.5rem;
    }

`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 3rem 0 3.5rem;
    border-top: 1px solid #fefefe;
    position-items: flex-start;
    
    @media screen and (max-width: 1030px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }
`;

const Title = styled.p`
    font-size: 3rem;
    line-height: 2;
    color: white;
    font-family: NeueUltra;

    @media screen and (max-width: 1030px) {
        font-size: 4rem;
    }

    @media screen and (max-width: 1030px) {
        width: 84%;  
        text-align: center;
    }

    @media screen and (max-width: 450px) {
        font-size: 2rem;
        text-align: left;
    }
`;

const ParagraphConatiner = styled.div`
    display: flex;
    flex-direction: column;
    width: 44%;

    @media screen and (max-width: 1030px) {
        width: 84%;  
    }
`;

const SubHead = styled.h1`
    font-size: 1.5rem;
    line-height: 2;
    color: white;
    font-family: Cont;

    @media screen and (max-width: 1030px) {
        text-align:  center;  
    }

    @media screen and (max-width: 1000px) {
        text-align:  center; 
    }

    @media screen and (max-width: 660px) {
        text-align:  center; 
        font-size: 1.1rem; 
    }

    @media screen and (max-width: 450px) {
        text-align:  left; 
        font-size: 0.8rem; 
    }

    /* ::before {
        content: url(/src/images/star.svg);
        position: absolute;
        width: 100%;
        height: 100%;
    } */

`;

const HugeNumber = styled.h1`
    font-size: 7.25rem;
    color: white;
    font-family: Cont;
    background-image: linear-gradient(35deg,#F7F1E3 0%,#F3E4D7 16.5%,#F4BBC8 33%,#CD84F1 49.5%,#9980FA 66%,#B0FCFF 82.5%,#4AD9FB 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;
    margin-top: 15px;

    @keyframes animatedText{
      to{
        background-position: 100%; 
      }
    }

    @media screen and (max-width: 1030px) {
        width: 84%;  
        text-align: center;
    }

    @media screen and (max-width: 450px) {
        text-align:  left; 
    }
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
    padding: 10px 78px;

    @media screen and (max-width: 500px) {
        padding: 10px 18px;
    }
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;





const Roadmap = () => {
    return (
        <Section>
                <TopContainer>
                    <Line />
                        <TopHeading>Future Roadmap</TopHeading>
                </TopContainer>
            <Container>
                <InnerContainer>
                    <Title>Phase</Title>
                    <ParagraphConatiner>
                        <SubHead>Genesis Core NFT Sale</SubHead>
                        <SubHead>Generative NFT Ring Lauch</SubHead>
                        <SubHead>Partnership Collaborations</SubHead>
                    </ParagraphConatiner>
                    <HugeNumber>/01*</HugeNumber>
                </InnerContainer>
                <InnerContainer>
                    <Title>Phase</Title>
                    <ParagraphConatiner>
                        <SubHead>Initial Value Offering Launch</SubHead>
                        <SubHead>Utility Token ICO</SubHead>
                        <SubHead>Release Of DeFi Component</SubHead>
                        <SubHead>Governance Token Launch</SubHead>
                        <SubHead>Exclusive Product Collaborations</SubHead>
                    </ParagraphConatiner>
                    <HugeNumber>/02</HugeNumber>
                </InnerContainer>
                <InnerContainer>
                    <Title>Phase</Title>
                    <ParagraphConatiner>
                        <SubHead>Release of Marketplace</SubHead>
                        <SubHead>VIP Membership Passes</SubHead>
                        <SubHead>All-In-One Super App</SubHead>
                        <SubHead>Exclusive Genesis Event</SubHead>
                    </ParagraphConatiner>
                    <HugeNumber>/03</HugeNumber>
                </InnerContainer>
            </Container>
        </Section>
    )
}

export default Roadmap