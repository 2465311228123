import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    width: 380px;
    height: 470px;
    display: inline-block;
    box-sizing: border-box;
    border: 6px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(199,248,255) 12.82%, rgb(146,201,249) 41.96%, rgb(145,134,231) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: transparent !important;
    color: white !important;
    border-radius: 25px;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;

    @keyframes animatedText{
      to{
        background-position: 100%; 
      }
    }
    

    @media screen and (max-width: 1370px){
      width: 330px;
      height: 400px;
    } 


    @media screen and (max-width: 1200px){
      width: 300px;
      height: 400px;
    }
`;


const HeadConatiner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardName = styled.h1`
    font-size: 28px;
    color: white;
    font-family: Cont;
    background-image: linear-gradient(35deg,#FBF4EC 0%,#ECD7C8 16.5%,#ffffff 33%,#ffffff 66%,#92C9F9 82.5%,#C7F8FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;
    margin-left: 13px;

    @keyframes animatedText{
      to{
        background-position: 100%; 
      }
    }

    @media screen and (max-width: 1200px){
      font-size: 24px;
    }
`;

const Heading = styled.h1`
    font-family: Cont;
    color: white;
    font-size: 20px;
    line-height: 21px;
    font-weight: 500;
    margin-left: 13px;
    margin-top: 30px;
`;

const Description = styled.h3`
    font-family: Cont;
    font-size: 26px;
    line-height: 33px;
    font-weight: 500;
    margin-left: 13px;
    margin-top: 30px;
    background-image: linear-gradient(35deg,#FBF4EC 0%,#ECD7C8 16.5%,#ffffff 33%,#ffffff 66%,#92C9F9 82.5%,#C7F8FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;

    @keyframes animatedText{
      to{
        background-position: 100%; 
      }
    }

    @media screen and (max-width: 1460px) {
      font-size: 18px;
    }

    @media screen and (max-width: 1360px) {
      font-size: 18px;

    }

    @media screen and (max-width: 1200px){
      font-size: 16px;
    }

    @media screen and (max-width: 920px) {
      font-size: 16px;
    }
`;



const SolutionCard = ({name, description}) => {
    return (
        <Wrapper>
            <HeadConatiner>
                <TextWrapper>
                    <CardName>{name}</CardName>
                    <Description>
                      {description}
                    </Description>
                </TextWrapper>
            </HeadConatiner>
        </Wrapper>
    )
};

export default SolutionCard;