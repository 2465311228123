import React from "react";
import styled, { keyframes }  from 'styled-components';
import { motion } from "framer-motion";

import BlackCoin from '../images/black2.mp4'

const VideoBackground = styled.div`
    overflow: hidden;
    width: 100%; 
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    @media screen and (max-width: 1360px) {
      width: 100%;
    }

    @media screen and (max-width: 1130px) {
      height: 90vh;
    }
/* 
    @media screen and (max-width: 1120px) {
      height: 90vh;
    }
     */

    @media screen and (max-width: 830px) {
      height: 80vh;
    }


    @media screen and (max-width: 650px) {
      height: 70vh;

    }
    @media screen and (max-width: 420px) {
      height: 60vh;
      
    }
    
`;  

const Video = styled.video`
  object-fit: contain;
  place-item: center;
  
  @media screen and (max-width: 1360px) {
      width: 90%;
    }

    @media screen and (max-width: 420px) {
      width: 90%;
    }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 400px;
  left: 0;
  margin-bottom: 50px;

  @media screen and (max-width: 850px) {
    top: 200px;
  }

  @media screen and (max-width: 450px) {
    top: 200px;
  }
  
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Headline = styled.h1`
    font-family: Neue;
    font-size: 50px;
    text-align: center;
    position: absolute;
    background-image: linear-gradient(35deg,#FBF4EC 0%,#ECD7C8 16.5%,#ffffff 33%,#ffffff 66%,#92C9F9 82.5%,#C7F8FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 10s infinite alternate-reverse;
    z-index: 1000;
    padding-top: 50px;
    width: 100%;
    padding: 20px;

    @keyframes animatedText{
      to{
        background-position: 100%; 
      }
    }

    @media screen and (max-width: 1460px) {
      font-size: 48px;
      text-align: center;
      position: absolute;
      padding-top: 50px;

    }

    @media screen and (max-width: 1360px) {
      font-size: 35px;
      text-align: center;
      position: absolute;
      padding-top: 50px;
  
    }

    @media screen and (max-width: 920px) {
      font-size: 35px;
      text-align: center;
      position: absolute;
      padding-top: 50px;
    }

    @media screen and (max-width: 420px) {
      font-size: 35px;
      text-align: center;
      position: absolute;
      padding-top: 0px;
    }
`;

const Paragraph = styled.p`
  font-family: Neue;
  font-size: 25px;
  width: 50%;
  color: #f1f2f6;
  margin-top: 300px;
  text-align: center;
  opacity: 0.9;
  padding: 0px;

  @media screen and (max-width: 920px) {
      font-size: 25px;
      width: 80%;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      width: 100%;
    }

    @media screen and (max-width: 468px) {
      font-size: 18px;
      width: 90%;
    }
`;

const NewButton = styled(motion.a)`
    padding: 0.875rem 1.5rem;
    margin-top: 40px;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(145,134,231) 12.82%, rgb(146,201,249) 41.96%, rgb(199,248,255) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    width: fit-content !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: fit-content !important;
    font-family: Neue;

    &:hover {
      background-position: left bottom;
    }

    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }
`;

export default function Home() {
  return (
    <VideoBackground>
            <Video  autoPlay='autoplay' muted loop>
              <source src={BlackCoin} type='video/mp4' />
            </Video>
            <Overlay>
            <Column>
                <Headline>We Shape What We Wear, What We Wear Shapes Us</Headline>
            <Paragraph>
              Wear Of Value will create a future for you to interact with physical goods like never before.
            </Paragraph>
            <NewButton 
              target="_blank"
              href='https://drive.google.com/file/d/1ia3IKc0oKWqLlZmSrLIXA4MLNQ70vuq5/view'
              whileHover={{
                scale: 1.1
              }}>
              Explore More
            </NewButton>
            </Column>
            </Overlay>
    </VideoBackground>
  );
}


           