import React from "react";
import styled from 'styled-components';

import { motion } from "framer-motion";


const Conatiner = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    background-image: linear-gradient(35deg,#F7F1E3 0%,#F3E4D7 16.5%,#F4BBC8 33%,#CD84F1 49.5%,#9980FA 66%,#B0FCFF 82.5%,#4AD9FB 100%);
    position: relative;
    height: 15rem;
    border-radius: 1rem;
    margin: 80px;
    overflow: hidden;
    z-index: 1;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1330px) {
      height: 10rem;
    }

    @media screen and (max-width: 1100px) {
      height: 20rem;
    }

    @media screen and (max-width: 1100px) {
      margin: 40px;
      height: 22rem;
    }

    @media screen and (max-width: 650px) {
      padding-left: 30px;
      height: 22rem;
    }

    @media screen and (max-width: 420px) {
      margin-top: 60px;
      height: 22rem;
      padding-left: 20px;
    }

    @media screen and (max-width: 400px) {
      margin-top: 120px;
      height: 22rem;
      padding-left: 20px;
    }

    @media screen and (max-width: 380px) {
      margin-top: 230px;
      height: 22rem;
      padding-left: 20px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
      gap: 20px;
    }

    @media screen and (max-width: 650px) {
      gap: 10px;
      padding-left: 0px;
    }
`;

const Head = styled.h2`
    font-family: Neue;
    font-size: 30px;
    font-weight: bold;
    line-height: 105%;
    letter-spacing: -0.0425rem;
    color: black;

    @media screen and (max-width: 1510px) {
      font-size: 30px;
      width: 100%;
      line-height: 105%;
    }

    @media screen and (max-width: 1330px) {
      font-size: 27px;
      width: 100%;
      line-height: 105%;
    }

    @media screen and (max-width: 1230px) {
      font-size: 26px;
      width: 100%;
      line-height: 105%;
    }

    @media screen and (max-width: 1100px) {
      font-size: 25px;
      width: 100%;
      line-height: 105%;
    }

    @media screen and (max-width: 650px) {
      font-size: 20px;
      width: 100%;
      line-height: 105%;
    }
`;


const ColumnCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

`;

const HeadOne = styled.h2`
    font-family: NeueUltra;
    font-size: 60px;
    font-weight: bold;
    line-height: 125%;
    letter-spacing: -0.0425rem;
    color: black;

    @media screen and (max-width: 1510px) {
      font-size: 50px;
      width: 100%;
      line-height: 105%;
    }

    @media screen and (max-width: 1330px) {
      font-size: 40px;
      width: 100%;
      line-height: 105%;
    }

    @media screen and (max-width: 650px) {
      font-size: 40px;
      width: 100%;
      line-height: 105%;
    }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled.p`
  font-family: Neue;
  font-size: 25px;
  width: 800px;
  color: black;

  @media screen and (max-width: 1440px) {
    width: 700px;
    font-size: 18px;
  }

  @media screen and (max-width: 1330px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1230px) {
      font-size: 15px;
      width: 540px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 25px;
      width: 540px;
    }

    @media screen and (max-width: 650px) {
      font-size: 18px;
      width: 90%;
    }
`;

const NewButton = styled(motion.a)`
    padding: 0.875rem 1.5rem;
    margin-top: 20px;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(199,248,255) 12.82%, rgb(146,201,249) 41.96%, rgb(145,134,231) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    width: fit-content !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: fit-content !important;
    font-family: Neue;

    &:hover {
      background-position: left bottom;
    }
`;


export default function Join() {
  return (
    <Conatiner>
      <Row>
          <ColumnCont>
            <Head>Say Hello to</Head>
            <HeadOne>Fashion 3.0</HeadOne>
          </ColumnCont>
          <Column>
              <Paragraph>
                The brands don't want you to know about Fashion 3.0 in the same way the Banks don't want you to know 
                about crypto. Don't play by their rules, play by yours.
              </Paragraph>
              <NewButton 
                target="_blank"
                href='https://drive.google.com/file/d/1ia3IKc0oKWqLlZmSrLIXA4MLNQ70vuq5/view'
                whileHover={{
                  scale: 1.1
              }}>
              Download the Litepaper
            </NewButton>
          </Column>
      </Row>
    </Conatiner>
  );
}
