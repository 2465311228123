import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const RowContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background-color: #141414;
    padding: 20px 25px 20px 25px;
    border-radius: 12px;
    
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    
`;

const InnerRow = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
`;

const Percentage = styled.p`
    padding: 0px;
    margin: 0px 10px 0px 0px;
    letter-spacing: 0.06em;
    font-size: 22px;
    font-weight: bold;
    opacity: 1;
    font-family: Cont;
    background-image: linear-gradient(175deg,#F7F1E3 0%,#F3E4D7 16.5%,#F4BBC8 33%,#CD84F1 49.5%,#9980FA 66%,#B0FCFF 82.5%,#4AD9FB 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
    animation: animatedText 8s infinite alternate-reverse;
    z-index: 1000;

    @keyframes animatedText{
      to{
        background-position: 100%; 
      }
    }
`;

const For = styled.div`
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.06em;
    font-size: 14px;
    color: white;
    font-weight: 400;
    font-family: Cont;
`;

const Text = styled.p`
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.06em;
    font-size: 12px;
    color: white;
    font-weight: lighter;
    opacity: 0.6;
`;

const InsideColumn = styled.div`
    display: flex;
    flex-direction: row;
    text-align: right;
    align-items: center;
    justify-content: center;
`;

const Tokens = styled.p`
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.06em;
    font-size: 12px;
    color: white;
    font-weight: bold;
    opacity: 0.6;
    font-family: Cont;
    margin-left: 10px;
`;

const Total = styled.p`
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.06em;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    font-family: Cont;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }   
`;

const TokenCard = ({ percentage, forWhat, lockText, noOf}) => {
    return (
            <RowContainer whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                    <ColumnContainer>
                            <InnerRow>
                                <Percentage>
                                    {percentage}
                                </Percentage>
                                <For>
                                    {forWhat}
                                </For>
                            </InnerRow>
                            <Text>
                            {lockText}
                            </Text>
                    </ColumnContainer>
                    <InsideColumn>
                            
                            <Total>
                                {noOf}
                            </Total>
                            {/* <Tokens>
                                Tokens
                            </Tokens> */}
                    </InsideColumn>
            </RowContainer>  
            )
}

export default TokenCard