import React from 'react';
import styled from 'styled-components';

import TokenCard from '../components/TokenCard';
import TokenData from '../data/TokenData';

import Token1 from '../images/token1.svg';

import Back from '../images/back76.svg';

const Container = styled.div`
    flex-direction: row;
    display: flex;
    width: calc(100% - 30px);
    border-radius: 24px 0px 0px 24px;
    margin: 20px 0px 42px auto;
    padding: 10px 60px 10px 60px;
    -webkit-box-align: center;
    align-items: center;
    gap: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media screen and (max-width: 1080px) {
        margin: -70px 0px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 70px;
        padding: 50px;
    }

    @media screen and (max-width: 520px) {
        margin: -20px 0px -48px;
    }

    @media screen and (max-width: 450px) {
        margin: 0px;
        padding: 20px
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    gap: initial;
    
    @media screen and (max-width: 1100px) {
        padding-top: 50px;
    }

    @media screen and (max-width: 450px) {
        padding-top: 30px;
    }
`;

const SmallHeading = styled.h2`
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: .02em;
    display: inline-block;
    margin: 0;
    color: white;
    font-family: Neue;
`;

const Heading = styled.h3`
    font-size: 40px;
    line-height: 48px;
    margin: 8px 0 0;
    font-weight: 600;
    color: white;
    font-family: Cont;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const Paragraph = styled.p`
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .02em;
    font-size: 16px;
    color: white;
    font-family: Neue;
    padding-bottom: 60px;

    @media screen and (max-width: 410px) {
        padding-top: 20px ;
    }
`;

const ImageContainer = styled.div` 
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 1200px;

    @media screen and (max-width: 420px){
        width: 100%;
        height: 100%;
        max-width: 1600px;
        max-height: 1600px;

    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const PercentageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    gap: 20px;

    @media screen and (max-width: 1080px) {
        width: 100%;
    }

    @media screen and (max-width: 540px) {
        display: none
    }
`;


const Tokenomics = () => {
    return (
        <Container>
            <ContentContainer>
                    <SmallHeading>
                        Tokenomics
                    </SmallHeading>
                    <Heading>
                         WOV Token Allocation
                    </Heading>
                    <Paragraph>
                            This is a breakdown of our WOV utility token when we launch our Initial Coin Offering in Phase 02 of
                            our roadmap. Further details on VOW Governance token will be released in a subsequent update.
                    </Paragraph>
                    <ImageContainer>
                        <Image src={Token1} alt="Tokenomics"/>
                    </ImageContainer>    
            </ContentContainer>
            <PercentageContainer>
                {TokenData.map((item, index) => (
                    <TokenCard
                        key={index}
                        percentage={item.percentage}
                        forWhat={item.forWhat}
                        noOf={item.noOf}
                    />
                ))}

            </PercentageContainer>
        </Container>
    )
}

export default Tokenomics