import React from 'react';
import styled from 'styled-components';

const Conatiner = styled.div`
    width: 260px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    width: 200px;
    height: 200px;
`;

const Name = styled.h1`
    font-size: 18px;
    color: white;
    font-family: NeueUltra;
    margin-top: 20px;
`;

const TeamCard = ({ team, name }) => {
  return (
        <Conatiner>
            <Image src={team} alt="team" />
            <Name>{name}</Name>
        </Conatiner>
  )
}

export default TeamCard