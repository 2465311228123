import React from 'react';
import styled from 'styled-components';

import Back from '../images/reveal.svg'
import BackOne from '../images/revealipad.svg'
import BackTwo from '../images/revealmob.svg'
import BackThree from '../images/revealtwo.svg'


import Hm from '../images/partners/hm.svg';
import Asos from '../images/partners/asos.svg';
import Shein from '../images/partners/shein.svg';
import Zara from '../images/partners/zara.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3rem!important;
    padding-bottom: 3rem!important;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1200px) {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important;
        background-image: url(${BackOne});
    }

    @media screen and (max-width: 992px) {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important;
        background-image: url(${BackOne});
        background-size: contain;
    }

    @media screen and (max-width: 592px) {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important;
    }

    @media screen and (max-width: 540px) {
        padding-top: 2rem!important;
        padding-bottom: 3rem!important;
        background-image: url(${BackTwo});
    }
`;

const CardTwo = styled.div`
    background-image: transparent;
    overflow: hidden;
    border-radius: 30px;
    margin-bottom: 300px;

    @media screen and (max-width: 1200px) {
        margin-bottom: 360px;
    }

`;


const CardInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem!important;
`;

const Line = styled.div`
    width: 80%;
    height: 2px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 1200px) {
        margin-top: 45px;
    }

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const CollectionGrid = styled.div`
    margin-right: auto;
    margin-left: auto;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    place-items: center;

    @media screen and (max-width: 1650px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 10px;
        grid-column-gap: 15px;  
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 10px;
        grid-column-gap: 15px;  
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
        margin-right: auto;
        margin-left: auto;
    }

    @media screen and (max-width: 510px) {
        
    }

    @media screen and (max-width: 485px) {
        grid-template-columns: 1fr;
        grid-row-gap: 0px;
        grid-column-gap: 0px;
        margin-right: auto;
        margin-left: auto;
    }
`;

const ImageIcon = styled.img`
    width: 160px;
    height: 160px;

    @media screen and (max-width: 650px) {
        width: 120px;
        height: 120px;
    }

    @media screen and (max-width: 550px) {
        /* visibility: hidden; */
        display: none;
        width: 100px;
        height: 100px;
    }
`;


const Partners = () => {
  return (
    <Container>
        
                    <Line />
                        <TopHeading>Partners</TopHeading>
            
            <CardTwo>    
                <CardInner>
                    <CollectionGrid>
                        <ImageIcon src={Hm} alt="Hm"/>
                        <ImageIcon src={Asos} alt="Asos"/>
                        <ImageIcon src={Shein} alt="Shein"/>
                        <ImageIcon src={Hm} alt="Uni"/>
                        <ImageIcon src={Zara} alt="Binance"/>
                        <ImageIcon src={Zara} alt="Binance"/>
                    </CollectionGrid>
                    </CardInner>
            </CardTwo>
    </Container>
  )
}

export default Partners;