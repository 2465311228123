import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import styled from 'styled-components';
import Hamburger from "./Hamburger";
import Logo from '../images/logo.svg'

const NewButton = styled.a`
    padding: 0.875rem 1.5rem;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(199,248,255) 12.82%, rgb(146,201,249) 41.96%, rgb(145,134,231) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    width: fit-content !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: fit-content !important;
    font-family: Neue;

    &:hover {
      background-position: left bottom;
    }

    @media screen and (max-width: 420px) {
      font-size: 0.8rem !important;
    }
`;


const LogoImage = styled.img`
    width: 100%;
    height: 100%;
    overflow: 'hidden';

    @media screen and (max-width: 600px) {
      width: 80%;
      height: 80%;
    }

    @media screen and (max-width: 480px) {
      width: 86%;
      height: 86%;
      margin-right: 40px;
    }
`;

const Header = ({ history }) => {
  // State of our Menu
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu"
  });
  // State of our button
  const [disabled, setDisabled] = useState(false);

  //Use Effect
  useEffect(() => {
    //Listening for page changes.
    history.listen(() => {
      setState({ clicked: false, menuName: "Menu" });
    });
  }, [history]);

  // Toggle menu
  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close"
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "Menu"
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close"
      });
    }
  };

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
    <header>
      <div className="container">
        <div className="wrapper">
          <div className="inner-header">
            <div className="logo">
              <Link to="/">
                <LogoImage src={Logo}/>
              </Link>
            </div>
            <div className="menu">
              <NewButton disabled={disabled} onClick={handleMenu}>
                {state.menuName}
              </NewButton>
            </div>
          </div>
        </div>
      </div>
      <Hamburger state={state} />
    </header>
  );
};

export default withRouter(Header);
