import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "./App.scss";

import Header from "./components/Header";

import Home from './pages/Home';
import WhatIsWear from './pages/WhatIsWear';
import Roadmap from "./pages/Roadmap";
import ContactUs from './pages/ContactUs';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <HelmetProvider>
        <Helmet>
          <title>Wear of Value</title>
          <meta
              name="description"
              content="Wear Of Value is an ecosystem that bridges physical Fashion & Luxury Goods with Web 3.0, leveraging Crypto, DeFi, and NFTs. Our WoV Genesis NFT represents the heart of the brand. It consists of 4 Tiers: Onyx, Gold, Platinum, and Diamond with a total collection of 10000 supplies that brings access to higher utility and benefits that change the game of the industry on how we interact with Fashion like never before."
            />
        </Helmet>
      </HelmetProvider>
      <div className="app-container">
        <Header />
        
          <div className="wrapper">
            <div className="home">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/WhatisWearOfValue" component={WhatIsWear} id="whatiswear"/>
                {/* <Route exact path="/roadmap" component={Roadmap} /> */}
                <Route exact path="/contactus" component={ContactUs} />
              </Switch>
            </div>
          </div>
       
      </div>
    </Router>
  );
}


export default App;
