import React from 'react';
import styled from 'styled-components';

import Holo from '../images/holo.svg'
import Batch from '../images/nftcard.svg'
import BackOne from '../images/nftcardmob.svg';

const Wrapper = styled.a`
    width: 340px;
    height: 500px;
    background-image: url(${Batch});
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    text-decoration: none;

    &:hover{
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(199,248,255) 12.82%, rgb(146,201,249) 41.96%, rgb(145,134,231) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: transparent !important;
    color: white !important;
    }

    @media screen and (max-width: 422px) {
        width: 320px;
        height: 500px;
        background-image: url(${BackOne});
    }
`;

const HeadConatiner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardName = styled.h1`
    font-size: 34px;
    color: white;
    font-family: NeueUltra;
`;

const ImgWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ImgContainer = styled.div`
    width: 300px;
    height: 380px;
    padding-right: 5px;
    padding-left: 5px;

    @media screen and (max-width: 422px) {
        width: 260px;
        height: 340px;
    }
`;

const Video = styled.video`
    width: 100%;
    height: 100%;: ;
`;

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
`;

const ImageContainer = styled.div`
    width: 160px;
    height: 30px;
    overflow: hidden;
    place-items: center;
`;

const CuratedImg = styled.img`
    width: 100%;
    height: 100%;
`;



const VideoCard = ({name, video}) => {
    return (
        <Wrapper target="_blank" href='https://opensea.io/collection/wearofvalue'>
            <HeadConatiner>
                <TextWrapper>
                    <CardName>{name}</CardName>
                </TextWrapper>
            </HeadConatiner>
            <ImgWrapper>
                <ImgContainer type='hidden' onContextMenu={e => e.preventDefault()}>
                <Video autoPlay='autoplay' muted loop 
                >
                    <source src={video} type='video/mp4' />
                </Video>
                </ImgContainer>
            </ImgWrapper>
            <BottomWrapper>
                <ImageContainer>
                <CuratedImg src={Holo} alt='Curated'/>
                </ImageContainer>
            </BottomWrapper>
        </Wrapper>
    )
};

export default VideoCard;