import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const Container = styled.section`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: black;
`;

const Section = styled.div`
    padding-left: 20%;
    padding-right: 20%;
    background-color: black;
    padding-top: 10%;

    @media screen and (max-width: 930px){
        padding-left: 16%;
        padding-right: 16%;
        padding-top: 15%;
    }

    @media screen and (max-width: 530px){
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 30%;
    }
`;

const Heading = styled.h1`
    line-height: 0.84;
    font-size: 12.93056vw;
    font-family: Neue;
    color: white;
    margin-top: 30px;
`;

const Para = styled.p`
    line-height: 1.6;
    font-size: 24px;
    margin-top: 4.59722vw;
    color: white;
    opacity: 0.9;
    font-family: NeueLight;

    @media screen and (max-width: 930px){
        font-size: 18px;
    }

    @media screen and (max-width: 530px){
        font-size: 15px;
    }
`;

const NewButton = styled(motion.a)`
    padding: 0.875rem 1.5rem;
    margin-top: 40px;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid transparent;
    background-image: linear-gradient(rgb(251,244,236), rgb(236,215,200)), linear-gradient(95.5deg, rgb(145,134,231) 12.82%, rgb(146,201,249) 41.96%, rgb(199,248,255) 75.06%, rgb(238,164,188) 107.66%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: rgb(53 55 58) 1px 1000px 1px inset;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    width: fit-content !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: fit-content !important;
    font-family: Neue;

    &:hover {
      background-position: left bottom;
    }

    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }
`;



const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-top: 20px;
`;


const ContactUs = () => {




  return (
      <Container>
        
    <Section>
        <Heading>
            Let's Connect
        </Heading>
        <Para>
        Thank You for your interest in Wear Of Value. We look forward to hearing from you about your project or any enquires. We will get back to you as soon as possible.
        </Para>  
    </Section>
            <ButtonContainer>
                        <NewButton 
                            target="_blank"
                            href='mailto:weare@wearofvalue.com'
                            whileHover={{
                                scale: 1.1
                            }}>
                            Send Message
                        </NewButton>
                    </ButtonContainer>
        </Container>
  )
}

export default ContactUs