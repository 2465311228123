import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Back from '../images/back13.svg';
import BackMob from '../images/aboutm.svg';
import BackTwo from '../images/aboutip.svg';
import Product from '../images/product.svg';
import Services from '../images/service.svg';
import Exper from '../images/exp.svg';

import MarqueeComponent from './Marquee';

const Section = styled.section`
    padding: 94px 78px;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
        background-image: url(${BackTwo});
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 20px 20px 20px 20px;
        background-image: url(${BackMob});
    }
`;

export const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
    padding-top: 20px;
`;

export const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

export const TopHeading1 = styled.h1`
    color: white;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

export const Paragraph = styled.p`
    margin-bottom: 25px;
    color: #ffffff;
    font-size: 24px;
    line-height: 2rem;
    font-weight: 400;
    font-family: NeueLight;

    @media screen and (max-width: 479px) {
        font-size: 18px;
       
    }
`;

export const GridLayout = styled.div`
    max-width: 1247px;
    margin-right: auto;
    margin-left: -30;
    align-items: stretch;
    grid-column-gap: 49px;
    grid-row-gap: 100px;
    display: grid;
    place-items: flex-start;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
    }
`;

export const Card = styled(motion.div)`
    padding-right: 20px;
    padding-left: 20px;
    min-width: 26vw;
    background-color: #141414;
    border-radius: 30px;
  

    @media screen and (max-width: 767px) {
        max-width: 600px;
    }
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 10px 20px;
    background-color: #141414;
    border-radius: 8px;
    box-shadow: 11px 0 28px 0px rgb(0 0 0 / 15%);

`;

export const Heading = styled.h1`
    font-family: Cont;
    color: white;
    
    line-height: 21px;
    font-weight: 500;
    margin-left: 13px;
    margin-top: 30px;
`;

export const Subtitle = styled.p`
    margin-top: 10px;
    color: white;
    font-size: 18px;
    line-height: 27.84px;
    font-weight: 400;
    margin-left: 13px;
    font-family: Neue;
`;


export const IconBlock =  styled.div`
    width: 120px; height: 120px ;
    display: flex;
    align-items: flex-start;
    padding-left: 6px;
`;

export const Img = styled.img`
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 0px;
    background-size: cover;
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const Container = styled.section`
    overflow: hidden;
    width: 100%;
    user-select: none;
    position: relative;
    z-index: 30;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const HeadingMain = styled.h1`
    font-family: Cont;
    font-size: 40px;
    transform: translate(0px, 0px);
    opacity: 1;
    color: white;
`;

const About = () => {
  return (

    <>
     <Container>
            <MarqueeComponent />
            </Container>
    
    <Section>
            <TopContainer>
            <Line />
                <TopHeading>About Wear of Value</TopHeading>
                <Paragraph>
                Wear Of Value is Innovating by building a bridge between the Fashion space and Web3.0 to shift back focus of Fashion
                towards the worth of Identity, and appreciating each Fashion Asset. In our Future, Businesses, Designers, Creators, and
                Everyone else in the space will be part of the Wovement.
                </Paragraph>
                <Paragraph>
                    To bring back Value in creating and owning Wearables For Everyone
                </Paragraph>
            </TopContainer>
            <TopContainer>
                <TopHeading1>Our NFT Utility</TopHeading1>
            </TopContainer>
            <GridLayout>
                <Card whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                        <IconContainer>
                                <IconBlock>
                                    <Img src={Product} alt='product'/>
                                </IconBlock>
                                <div style={{ width: '100%'}}>
                                <Heading>Products</Heading>
                                <Subtitle>
                                    Connecting physical items with a digital equivalent to function like an asset.
                                </Subtitle>
                                </div>
                            </IconContainer>
                </Card>
                    <Card whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>                       
                        <IconContainer>
                            <IconBlock>
                                <Img src={Services} alt='services'/>
                            </IconBlock>
                            <div>
                                <Heading>Services</Heading>
                                <Subtitle>
                                    Providing valuable services to maximize the value of your fashion assests.
                                </Subtitle>
                                </div>
                        </IconContainer> 
                        
                    </Card>
                    <Card whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                        <IconContainer>
                            <IconBlock>
                                <Img src={Exper} alt='experience'/>
                            </IconBlock>
                            <div>
                                <Heading>Experiences</Heading>
                                <Subtitle>
                                      Creating unique experiences around the interaction between you and assets.  
                                </Subtitle>
                                </div>
                        </IconContainer>    
                    </Card>
            </GridLayout>
    </Section>
    </>
  )
}

export default About