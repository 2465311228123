import React from 'react';
import styled from 'styled-components';


import Card1 from '../images/card1.svg';
import Card2 from '../images/card2.svg';
import Card3 from '../images/card3.svg';
import Card4 from '../images/card4.svg';

import Back from '../images/back87.svg';

const Section = styled.section`
    padding: 94px 78px;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media screen and (max-width: 1200px) {
        padding: 4px 78px;
    }

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 30px;
    }
`;

const Container = styled.div`
    display: block;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;

    @media screen and (max-width: 991px) {
        max-width: 728px;
    }

    &:before {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }

    &:after {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const LayoutGrid = styled.div`
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    margin-bottom: 0;
    grid-column-gap: 20px;
    grid-row-gap: 18px;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;

    @media screen and (max-width: 991px) {
        grid-column-gap: 24px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr ;
    }
`;

const Card = styled.div`
    display: flex;
    margin-bottom: 20px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 24px;
    color: #000;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(100.5px);
    -webkit-backdrop-filter: blur(100.5px);
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const Market = () => {
  return (
    <Section>
         <TopContainer>
                    <Line />
                        <TopHeading>Market Opportunity</TopHeading>
                </TopContainer>
        <Container> 
            <LayoutGrid>
                <Card>
                    <Image src={Card1} alt="" />
                </Card>
                <Card>
                    <Image src={Card2} alt="" />
                </Card>
                <Card>
                    <Image src={Card3} alt="" />
                </Card>
                <Card>
                    <Image src={Card4} alt="" />
                </Card>
            </LayoutGrid>
        </Container>
    </Section>
  )
}

export default Market