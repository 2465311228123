import React from 'react';
import styled from 'styled-components';

import Back from '../images/back11.svg';

const Section = styled.section`
    padding: 94px 78px;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    display: block;
    background-position: right;

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 40px 20px 50px 20px;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

`;

const Heading = styled.h2`
    text-align: justify;
    font-size: 52px;
    line-height: 1.25;
    color: white;
    font-family: Cont;
    text-transform: uppercase;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        font-size: 42px;
    }

    @media screen and (max-width: 620px) {
        font-size: 38px;
    }

    @media screen and (max-width: 530px) {
        font-size: 32px;
        padding: 10px;
    }

    @media screen and (max-width: 450px) {
        font-size: 28px;
        padding: 0px;
        width: 100%;
    }

    @media screen and (max-width: 430px) {
        font-size: 24px;
        padding: 0px;
    }
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
`;

const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

const Vision = () => {
    return (
        <Section>
            <TopContainer>
                <Line />
                    <TopHeading>Vision </TopHeading>
            </TopContainer>
            <Container> 
                <Heading>
                    BY EMPOWERING THE PEOPLE WITH A PLATFORM WHERE THEY CAN TRULY OWN, MAXIMIZE UTILITY, AND CREATE ASSETS OF VALUE
                </Heading>
                
            </Container>
        </Section>
    )
};

export default Vision;
