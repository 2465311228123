import React from 'react';
import styled from 'styled-components'

import HeroSection from '../components/HeroSection';
import Join from '../components/Join';
import CrossChain from '../components/CrossChain';
import VideoSection from '../components/VideoSection';
import About from '../components/About';
import Mission from '../components/Mission';
import Vision from '../components/Vision';
import Tokenomics from '../components/Tokenomics';
import Problems from '../components/Problems';
import Market from '../components/Market';
import NewFooter from '../components/NewFooter';
import WoVUtility from '../components/WoVUtility';
import SolutionSection from '../components/SolutionSection';
import Partners from '../components/Partners';
import NewSocial from '../components/NewSocial';
import TeamSection from '../components/TeamSection';

import Roadmap from '../pages/Roadmap';


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: block;
`;

const Home = () => {
    return (
        <Container>
            <HeroSection />
            <Join />
            <CrossChain />
            <VideoSection />
            <About />
            <Mission />
            <Vision />
            <Problems />
            <SolutionSection />
            <WoVUtility />
            <Tokenomics />
            <Market />
            <Roadmap />
            <TeamSection />
            <Partners />
            <NewSocial />
            <NewFooter />

        </Container>
    )
}

export default Home