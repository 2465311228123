import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Back from '../images/whatis.svg';


const Section = styled.section`
    padding: 94px 78px;
    background-image: url(${Back});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;

    @media screen and (max-width: 1200px) {
        padding: 4px 78px 200px;
    }

    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
        background-image: url(${Back});
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }


    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 3px -2px 80px 20px;
    }
`;

export const TopContainer = styled.div`
    max-width: 1200px!important;
    position: relative;
    z-index: 1;
`;

export const TopHeading = styled.h1`
    color: white;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

export const TopHeading1 = styled.h1`
    color: white;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 500;
    font-family: Cont;
    letter-spacing: -.025em;
    margin-bottom: 55px;

    @media screen and (max-width: 479px) {
        font-size: 25px;
    }
`;

export const Paragraph = styled.p`
    margin-bottom: 25px;
    color: white;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    font-family: Neue;

    @media screen and (max-width: 479px) {
        font-size: 14px;
        margin-bottom: 25px;
    }
`;


export const GridLayout = styled.div`
    max-width: 1247px;
    margin-right: auto;
    margin-left: -30;
    align-items: stretch;
    grid-column-gap: 89px;
    grid-row-gap: 100px;
    display: grid;
    place-items: flex-start;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 42px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
    }
`;

export const Card = styled(motion.div)`
    padding-right: 20px;
    padding-left: 20px;
    min-width: 30vw;
    background-color: #141414;
    border-radius: 30px;
  

    @media screen and (max-width: 767px) {
        max-width: 600px;
    }
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 10px 20px;
    background-color: #141414;
    /* border-bottom: 4px solid #f4ae6e; */
    border-radius: 8px;
    box-shadow: 11px 0 28px 0px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */

`;

export const Heading = styled.h1`
    font-family: Cont;
    color: white;
    font-size: 20px;
    line-height: 21px;
    font-weight: 500;
    margin-left: 13px;
    margin-top: 30px;
`;

export const Subtitle = styled.p`
    margin-top: 10px;
    color: white;
    font-size: 16px;
    line-height: 27.84px;
    font-weight: 400;
    margin-left: 13px;
    font-family: Neue;
`;


export const IconBlock =  styled.div`
    width: 140px;
    height: 140px;
    display: flex;
    align-items: flex-start;
    padding-left: 6px;
`;

export const Img = styled.img`
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 0px;
    background-size: cover;
`;

const Line = styled.div`
    width: 60%;
    height: 1px;
    background: #a4b0be;
    z-index: 1000;
    margin-bottom: 3rem!important;
`;

const WhatIsWear = () => {
  return (
    <Section id="whatiswear">
         <Helmet>
              <title>What is Wear</title>
              <meta name="description" content="About WOV" />
              <link rel='canonical' href='/WhatisWearOfValue' />
          </Helmet>
            <TopContainer>
            <Line />
                <TopHeading>What is 
                    <br/>
                    Wear of&nbsp;Value
                </TopHeading>
                <Paragraph>
                WoV is an ecosystem that bridges conventional users who consume physical
                Fashion & Luxury Goods to the Metaverse ( Virtual Space ) in Web 3.0, leveraging Crypto, DeFi and NFTs.
                </Paragraph>
                <Paragraph>
                    Within our token economy, our utility token in the ecosystem will be used as a means of currency, 
                    reward, profit sharing, cash back, and much more. The other token that complements our ecosystem 
                    will be our Governance token, used in WOV DAO as means of Voting Rights, Proposing and Deciding on 
                    how the community will shape the future of the protocol.
                </Paragraph>
            </TopContainer>
           
    </Section>
    )
}

export default WhatIsWear